(function () {
    'use strict';

    angular.module('nfseApp')
        .factory('Principal', function Principal($q, Account, Configuracao, localStorageService, PrestadorServicos) {
            var _identity, _configuracao,
                _authenticated = false;

            return {
                isIdentityResolved: function () {
                    return angular.isDefined(_identity);
                },
                isAuthenticated: function () {
                    return _authenticated;
                },
                isInRole: function (role) {
                    if (!_authenticated || !_identity || !_identity.roles) {
                        return false;
                    }
                    if (_identity.roles.indexOf(role) !== -1) {
                        return true;
                    }
                    var prestadorPrincipal = localStorageService.get('prestadorPrincipal');
                    return prestadorPrincipal && prestadorPrincipal.roles && prestadorPrincipal.roles.indexOf(role) !== -1;

                },
                isInAnyRole: function (roles) {
                    if (!_authenticated || !_identity.roles) {
                        return false;
                    }

                    for (var i = 0; i < roles.length; i++) {
                        if (this.isInRole(roles[i])) {
                            return true;
                        }
                    }

                    return false;
                },
                authenticate: function (identity) {
                    _identity = identity;
                    _authenticated = identity !== null;
                },

                config: function (force) {
                    var deferred = $q.defer();
                    if (force === true) {
                        _configuracao = undefined;
                    }
                    if (angular.isDefined(_configuracao)) {
                        deferred.resolve(_configuracao);
                        return deferred.promise;
                    }
                    Configuracao.get().$promise
                        .then(function (configuracao) {
                            _configuracao = configuracao;
                            deferred.resolve(_configuracao);
                        })
                        .catch(function (error) {
                            _configuracao = null;
                            deferred.resolve(_configuracao);
                        });

                    return deferred.promise;

                },

                identity: function (force) {
                    var deferred = $q.defer();

                    if (force === true) {
                        _identity = undefined;
                    }

                    // check and see if we have retrieved the identity data from the server.
                    // if we have, reuse it by immediately resolving
                    if (angular.isDefined(_identity)) {
                        deferred.resolve(_identity);

                        return deferred.promise;
                    }

                    // retrieve the identity data from the server, update the identity object, and then resolve.
                    Account.get().$promise
                        .then(function (account) {
                            _identity = account.data;
                            _authenticated = true;
                            if (_identity) {
                                localStorageService.set('prestadorPrincipal', _identity.empresa);
                            }
                            deferred.resolve(_identity);
                        })
                        .catch(function () {
                            _identity = null;
                            _authenticated = false;
                            deferred.resolve(_identity);
                        });

                    return deferred.promise;
                }
            };
        });
})();