(function () {
    'use strict';

    angular.module('nfseApp')
        .factory('Auth', function Auth($rootScope, $state, $q, $translate, Principal, AuthServerProvider, Account, Register, Activate, Password, PasswordResetInit, PasswordResetFinish) {
            return {
                login: function (credentials, callback) {
                    var cb = callback || angular.noop;
                    var deferred = $q.defer();

                    AuthServerProvider.login(credentials).then(function (data) {
                        // retrieve the logged account information
                        Principal.identity(true).then(function (account) {

                            // After the login the language will be changed to
                            // the language selected by the user during his registration
                            //$translate.use(account.langKey); // comentado para usar sempre o pt-br
                            //$translate.refresh();
                            deferred.resolve(data);
                        });
                        return cb();
                    }).catch(function (err) {
                        this.logout();
                        deferred.reject(err);
                        return cb(err);
                    }.bind(this));

                    return deferred.promise;
                },

                logout: function () {
                    AuthServerProvider.logout();
                    Principal.authenticate(null);
                },

                authorize: function (force) {
                    return Principal.identity(force)
                        .then(function () {
                            var isAuthenticated = Principal.isAuthenticated();

                            if ($rootScope.toState.data.roles && $rootScope.toState.data.roles.length > 0 && !Principal.isInAnyRole($rootScope.toState.data.roles)) {
                                if (isAuthenticated) {
                                    // user is signed in but not authorized for desired state
                                    $state.go('home');
                                } else {
                                    // user is not authenticated. stow the state they wanted before you
                                    // send them to the signin state, so you can return them when you're done
                                    $rootScope.returnToState = $rootScope.toState;
                                    $rootScope.returnToStateParams = $rootScope.toStateParams;

                                    // now, send them to the signin state so they can log in
                                    $state.go('login');
                                }
                            }
                        });
                },
                createAccount: function (account, callback) {
                    var cb = callback || angular.noop;

                    return Register.save(account,
                        function () {
                            return cb(account);
                        },
                        function (err) {
                            this.logout();
                            return cb(err);
                        }.bind(this)).$promise;
                },

                updateAccount: function (account, callback) {
                    var cb = callback || angular.noop;

                    return Account.save(account,
                        function () {
                            return cb(account);
                        },
                        function (err) {
                            return cb(err);
                        }.bind(this)).$promise;
                },

                activateAccount: function (key, callback) {
                    var cb = callback || angular.noop;

                    return Activate.get(key,
                        function (response) {
                            return cb(response);
                        },
                        function (err) {
                            return cb(err);
                        }.bind(this)).$promise;
                },

                changePassword: function (trocarSenhaDTO, callback) {
                    var cb = callback || angular.noop;
                    return Password.save(trocarSenhaDTO, function () {
                        return cb();
                    }, function (err) {
                        return cb(err);
                    }).$promise;
                },

                resetPasswordInit: function (mail, callback) {
                    var cb = callback || angular.noop;

                    return PasswordResetInit.save(mail, function () {
                        return cb();
                    }, function (err) {
                        return cb(err);
                    }).$promise;
                },

                resetPasswordFinish: function (key, newPassword, callback) {
                    var cb = callback || angular.noop;

                    return PasswordResetFinish.save(key, newPassword, function () {
                        return cb();
                    }, function (err) {
                        return cb(err);
                    }).$promise;
                }
            };
        });
})();
